<template>
  <TransitionGroup name="fade" mode="out-in">
    <div v-for="modal in modals" :key="modal.type">
      <component
        :is="modal.type"
        v-bind="modal.props"
        @commit="handleCommitModal($event, modal)"
        @dismiss="handleDismissModal(modal)"
      />
    </div>
  </TransitionGroup>
</template>

<script setup lang="ts">
import type { IModal } from '~/models'

const modals = ref<IModal[]>([])

const handleShowModal = (event: CustomEvent) => {
  const { type, props } = event.detail
  modals.value = [
    ...modals.value,
    {
      type,
      props,
    },
  ]
}

const handleHideModal = (event: CustomEvent) => {
  modals.value = modals.value.filter(modal => modal.type !== event.detail.type)
}

const handleCommitModal = (payload: any, modal: any) => {
  const event = new CustomEvent('modal:commit', {
    detail: {
      isSuccess: true,
      data: payload,
      modal,
    },
  })
  window.document.dispatchEvent(event)
}

const handleDismissModal = (modal: Modal) => {
  const event = new CustomEvent('modal:dismiss', {
    detail: {
      isSuccess: false,
      modal,
    },
  })
  window.document.dispatchEvent(event)
}

watch(modals, (newValue) => {
  const bodyClasses = window.document.body.classList

  if (newValue.length === 0) {
    bodyClasses.remove('overflow-hidden')
  } else if (!bodyClasses.contains('overflow-hidden')) {
    bodyClasses.add('overflow-hidden')
  }
})

onMounted(() => {
  window.document.addEventListener('modal:show', handleShowModal as EventListener)
  window.document.addEventListener('modal:hide', handleHideModal as EventListener)
})

onUnmounted(() => {
  window.document.body.classList.remove('overflow-hidden')
  window.document.removeEventListener('modal:show', handleShowModal as EventListener)
  window.document.removeEventListener('modal:hide', handleHideModal as EventListener)
})
</script>

<script lang="ts">
// FIXME: migrate to newer modal
export default {
  components: {
    'modal-navbar': defineAsyncComponent(() => import('~/components/modals/ModalNavbar.vue')),
    'modal-locale-switcher': defineAsyncComponent(() => import('~/components/modals/ModalLocaleSwitcher.vue')),
    'modal-overlay-gallery': defineAsyncComponent(() => import('~/components/modals/ModalOverlayGallery.vue')),
    'modal-share-tutorial': defineAsyncComponent(() => import('~/components/modals/ModalShareTutorial.vue')),
    'modal-tip-summary': defineAsyncComponent(() => import('~/components/modals/ModalTipSummary.vue')),
    'modal-final-checks-vehicle-change': defineAsyncComponent(() => import('~/components/modals/ModalFinalChecksVehicleChange.vue')),
    'modal-check-deposit': defineAsyncComponent(() => import('~/components/modals/ModalCheckDeposit.vue')),
    'modal-confirm-language': defineAsyncComponent(() => import('~/components/modals/ModalConfirmLanguage.vue')),
  },
}
</script>
