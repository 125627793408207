import debounce from "lodash/debounce"
import dayjs from "dayjs"
import { storeToRefs } from 'pinia'
import { BookingSubType, type Onboarding, OnboardingPageType } from "~/services/entities"
import { generateUrlWithQuery } from "~/services/helpers"
import { useOnboardingRepository } from "../repositories"
import useBooking from "./useBooking"
import type { Page } from "./useTutorialPageNavigation"
import { useCheckingFlow } from "./useOnboardingFlow/useCheckingFlow"
import { useReturnFlow } from "./useOnboardingFlow/useReturnFlow"
import { useTutorialFlow } from "./useOnboardingFlow/useTutorialFlow"
import { useManufacturersStore } from "~/stores/manufacturers.store"

export interface PageWithTracking extends Page {
  disabled?: boolean
  visited: boolean
  seen: boolean
  params: Object
  version: number
}

const shouldTrack = ref(false)
const pageWithTrackingList = ref<PageWithTracking[]>([])
const isReady = ref(false)
const currentPageId = ref('1')

const getPageById = (pageId: string) => {
  const page = pageWithTrackingList.value.find(page => page.id === pageId)

  if (!page) {
    throw new NotFoundPage(pageId)
  }

  return page
}



export class GuardPageException extends Error {
  fallbackId: string

  constructor(message: string, fallbackId: string) {
    super(message)
    this.fallbackId = fallbackId
  }
}

export class NotFoundPage extends Error {
  constructor(id: string) {
    super(`Not found page #${id}`)
  }
}

export default function () {
  const {
    fetchTrackingStatus,
    updateTrackingStatus,
    checkTrackingTipStatus,
    approveTrackingTipStatus,
    doneDamageCheck,
    trackStartFinalChecks,
  } = useOnboardingRepository()
  const debouncedUpdateTrackingStatus = debounce(updateTrackingStatus, 300)
  const { initBooking, booking, bookingVersion } = useBooking()
  const config = useRuntimeConfig()
  const { currentLocale } = useLocale()
  const route = useRoute()
  const manufacturersStore = useManufacturersStore()
  const { manufacturers, bookingManufacturerModel } = storeToRefs(manufacturersStore)

  const { checkingPages, initCheckingPages } = useCheckingFlow()
  const { returnPages, initReturnPages } = useReturnFlow()
  const { tutorialPages, initTutorialPages } = useTutorialFlow()

  const initOnboardingFlow = async (onboarding: Onboarding, initialPageId?: string) => {
    isReady.value = false

    await initBooking()
    const { data } = await fetchTrackingStatus(booking.value?.bookingNumber)

    // TODO: isInCheckingFlow ->
    // check if current onboarding page is correct with booking
    const authenticatedOnboardingPageUuids = data.map(page => page.uuid)
    const currentOnboardingPageUuids = onboarding.onboardingPages.map(page => page.uuid)
    const isSameOnboarding = currentOnboardingPageUuids.every(uuid => authenticatedOnboardingPageUuids.includes(uuid))
      && authenticatedOnboardingPageUuids.every(uuid => currentOnboardingPageUuids.includes(uuid))
    shouldTrack.value = isSameOnboarding


    manufacturersStore.initManufacturersStore({
      onboarding,
      booking: booking.value!,
    })
    initTutorialPages({
      onboardingPages: onboarding.onboardingPages,
      hasSupportTrees: onboarding.hasSupportTrees,
      trackings: data,
    })
    initCheckingPages({
      onboardingPages: onboarding.onboardingPages,
      booking: booking.value!,
      trackings: data,
      canStartChecking: canStartChecking.value,
      initialIndex: tutorialPages.value.length,
    })
    initReturnPages({
      initialIndex: tutorialPages.value.length + checkingPages.value.length,
    })

    pageWithTrackingList.value = [
      ...tutorialPages.value,
      ...checkingPages.value,
      ...returnPages.value,
    ]

    try {
      navigateToPage(initialPageId)
    } catch (error) {
      throw error
    } finally {
      isReady.value = true
    }

    isReady.value = true
  }

  const isPageVisited = (pageId: string) => {
    const page = getPageById(pageId)
    return page.visited
  }

  const isPageSeen = (pageId: string) => {
    const page = getPageById(pageId)
    return page.seen
  }

  const navigateToPage = (pageId: string | undefined, force = false) => {
    const notSeenPage = pageWithTrackingList.value.find(page => !page.seen)
    if (!notSeenPage) {
      // Customer viewed all pages, display first page
      currentPageId.value = pageId || '1'
      return
    }

    const nextPage = pageId ? getPageById(pageId) : null
    if (!nextPage) {
      // Page id is undefined, go to the last read page
      const canGoToNotSeenPage = !notSeenPage.disabled
      currentPageId.value = canGoToNotSeenPage ? notSeenPage.id! : pageWithTrackingList.value[notSeenPage.index - 1]?.id ?? '1'
      return visitedPage(currentPageId.value)
    }

    if (!force && nextPage.index > notSeenPage.index) {
      const fallbackId = notSeenPage.id!
      throw new GuardPageException('Not authorized for accessing', fallbackId)
    }

    currentPageId.value = pageId!
    visitedPage(currentPageId.value)
    // if (!force && !canStartChecking.value) {
    //   const fallbackId = '1'
    //   throw new GuardPageException('Not authorized for accessing', fallbackId)
    // }
  }

  const isPageTracked = (pageId: string) => {
    const page = getPageById(pageId)

    if (
      page.type === OnboardingPageType.CERTIFICATE
    ) {
      return tutorialPages.value.every(page => page.seen)
    }

    if (page.type === OnboardingPageType.CONGRATULATION) {
      return checkingPages.value.every(page => page.seen)
    }

    if (page.type === OnboardingPageType.THANK_YOU) {
      return returnPages.value.every(page => page.seen)
    }

    return page.seen
  }

  const trackPage = async (pageId: string) => {
    if (isPageTracked(pageId)) {
      return
    }

    const page = getPageById(pageId)
    // TODO: wait for equipment check page tracking
    if (![
      OnboardingPageType.CERTIFICATE,
      OnboardingPageType.CONGRATULATION,
      OnboardingPageType.THANK_YOU,
    ].includes(page.type)) {
      await debouncedUpdateTrackingStatus(page, booking.value?.bookingNumber)
    }

    pageWithTrackingList.value = pageWithTrackingList.value.map((_page) => {
      if (_page.id !== page.id) {
        return _page
      }

      _page.seen = true

      return _page
    })
  }

  const currentPage = computed(() => {
    const page = getPageById(currentPageId.value)
    return page
  })

  const updateCurrentPageParams = (params: Object) => {
    currentPage.value.params = {
      ...currentPage.value.params,
      ...params,
    }
  }

  const nextPageLink = computed(() => {
    if (currentPage.value.type === OnboardingPageType.THANK_YOU) {
      return caHomepage.value
    }
    const nextPage = pageWithTrackingList.value[currentPage.value.index + 1]
    if (!nextPage) {
      return null
    }
    return generateUrlWithQuery({ page: nextPage.id })
  })

  const caHomepage = computed(() => {
    const caHost = `${config.public.CA_HOST}/${currentLocale.value}`
    if (!booking.value) {
      return caHost
    }

    const url = `${caHost}/${booking.value.bookingNumber}/overview`
    if (!route.query.token) {
      return url
    }
    return `${url}?token=${route.query.token}`
  })

  const caInsurancePage = computed(() => {
    const caHost = `${config.public.CA_HOST}/${currentLocale.value}`
    if (!booking.value) {
      return caHost
    }
    const url = `${caHost}/${booking.value.bookingNumber}/insurances`
    if (!route.query.token) {
      return url
    }
    return `${url}?token=${route.query.token}`
  })

  const caCampervanPage = computed(() => {
    const caHost = `${config.public.CA_HOST}/${currentLocale.value}`
    if (!booking.value) {
      return caHost
    }
    const url = `${caHost}/${booking.value.bookingNumber}/campervan`
    if (!route.query.token) {
      return url
    }
    return `${url}?token=${route.query.token}`
  })

  const prevPageLink = computed(() => {
    const prevPage = pageWithTrackingList.value[currentPage.value.index - 1]
    if (!prevPage) {
      return null
    }
    return generateUrlWithQuery({ page: prevPage.id })
  })

  const visitedPage = async (pageId: string) => {
    if (isPageVisited(pageId)) {
      return
    }
    const page = getPageById(pageId)

    const shouldEnableReturnChecklist = currentPage.value.id === OnboardingPageType.CONGRATULATION

    pageWithTrackingList.value = pageWithTrackingList.value.map(_page => {
      if (shouldEnableReturnChecklist && returnPages.value.find(page => page.id ===_page.id)) {
        return {
          ..._page,
          disabled: false,
        }
      }

      if (_page.id !== page.id) {
        return _page
      }

      _page.visited = true

      return _page
    })
  }

  const canStartChecking = computed(() => {
    const today = dayjs().startOf('day')
    const isPickupDay = today.isAfter(booking.value?.agreedPickupDatetime, 'day') || today.isSame(booking.value?.agreedPickupDatetime, 'day')

    return isPickupDay
  })

  const isStationInUSAndCA = computed(() => {
    return ['US', 'CA'].includes(booking.value!?.pickupStation)
  })

  // const tutorialPages = computed(() => {
  //   return pageWithTrackingList.value.filter(page => [OnboardingPageType.ONBOARDING, OnboardingPageType.CERTIFICATE].includes(page.type))
  // })

  // const checkingPages = computed(() => {
  //   return pageWithTrackingList.value.filter(page => [...CHECKING_PAGES, OnboardingPageType.CONGRATULATION].includes(page.type))
  // })

  // const returnPages = computed(() => {
  //   return pageWithTrackingList.value.filter(page => [...RETURN_PAGES, OnboardingPageType.THANK_YOU].includes(page.type))
  // })

  const firstFinalCheckPage = computed(() => {
    return checkingPages.value[0]
  })

  const isInTutorialFlow = computed(() => {
    return !!tutorialPages.value.find(page => page.id === currentPage.value.id)
  })

  const isInCheckingFlow = computed(() => {
    return !!checkingPages.value.find(page => page.id === currentPage.value.id)
  })

  const isInReturnFlow = computed(() => {
    return !!returnPages.value.find(page => page.id === currentPage.value.id)
  })

  const isTutorialFlowFinished = computed(() => {
    return tutorialPages.value.every(page => page.seen)
  })

  const isCheckingFlowFinished = computed(() => {
    return checkingPages.value.every(page => page.seen)
  })

  const isReturnFlowFinished = computed(() => {
    // TODO: change to seen when tracking for checklist is implemented
    return returnPages.value.every(page => page.visited)
  })

  return {
    initOnboardingFlow,
    pageWithTrackingList,
    currentPage,
    updateCurrentPageParams,
    updateTrackingStatus:
      (page: PageWithTracking) => updateTrackingStatus(page, booking.value?.bookingNumber),
    debouncedUpdateTrackingStatus:
      (page: PageWithTracking) => debouncedUpdateTrackingStatus(page, booking.value?.bookingNumber),
    checkTrackingTipStatus:
      (page: PageWithTracking, tip, status: string) => checkTrackingTipStatus(page, tip, status, booking.value?.bookingNumber),
    approveTrackingTipStatus:
      (page: PageWithTracking, tip, status: string) => approveTrackingTipStatus(page, tip, status, booking.value?.bookingNumber),
    // TODO: move inside useCheckingFlow when booking is available in store
    doneDamageCheck:
      (page: PageWithTracking) => doneDamageCheck(page, booking.value?.bookingNumber),
    trackStartFinalChecks:
      () => trackStartFinalChecks(booking.value?.bookingNumber),
    navigateToPage,
    prevPageLink,
    nextPageLink,
    visitedPage,
    trackPage,
    isPageSeen,
    isPageVisited,
    isReady,
    canStartChecking,
    booking,
    bookingVersion,
    tutorialPages,
    isInTutorialFlow,
    isTutorialFlowFinished,
    checkingPages,
    isInCheckingFlow,
    isCheckingFlowFinished,
    isStationInUSAndCA,
    firstFinalCheckPage,
    returnPages,
    isInReturnFlow,
    isReturnFlowFinished,
    caHomepage,
    caInsurancePage,
    caCampervanPage,

    manufacturers,
    bookingManufacturerModel,
  }
}
